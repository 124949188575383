exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-services-analytics-jsx": () => import("./../../../src/pages/services/analytics.jsx" /* webpackChunkName: "component---src-pages-services-analytics-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-services-live-streaming-jsx": () => import("./../../../src/pages/services/live-streaming.jsx" /* webpackChunkName: "component---src-pages-services-live-streaming-jsx" */),
  "component---src-pages-services-social-media-jsx": () => import("./../../../src/pages/services/social-media.jsx" /* webpackChunkName: "component---src-pages-services-social-media-jsx" */),
  "component---src-pages-services-veo-camera-hire-jsx": () => import("./../../../src/pages/services/veo-camera-hire.jsx" /* webpackChunkName: "component---src-pages-services-veo-camera-hire-jsx" */),
  "component---src-pages-services-video-editing-jsx": () => import("./../../../src/pages/services/video-editing.jsx" /* webpackChunkName: "component---src-pages-services-video-editing-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-what-is-the-playmaker-hub-jsx": () => import("./../../../src/pages/what-is-the-playmaker-hub.jsx" /* webpackChunkName: "component---src-pages-what-is-the-playmaker-hub-jsx" */)
}

